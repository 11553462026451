
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























.modal-content {
  text-align: center;

  h3 {
    margin: 2rem 0;
  }

  &__button {
    margin-top: 4rem;

    button {
      margin-right: 1rem;
    }
  }
}
