
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































































































.facets {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 4rem;

  @include mq(m) {
    flex-flow: row wrap;
  }

  &__search {
    width: 100%;
    margin-bottom: 2rem;
    @include mq(m) {
      max-width: 41.9rem;
      margin-bottom: unset;
    }

    .input-wrapper {
      position: relative;

      &:after {
        content: url('../../../assets/svg/commune/search.svg');
        width: 1.9rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1rem);
        transition: opacity 0.3s ease-in-out;
      }

      &:focus-within:after {
        opacity: 0.5;
      }
    }

    input.search {
      width: 100%;
      border: 0;
      background-color: $c-gray-lightest;
      border-radius: 0.5rem;
      height: 5.5rem;
      padding: 1rem 2rem;

      &:focus {
        border: 1px solid $c-gray-darker;
        background-color: $c-white;
      }
    }
  }

  &__filters {
    font-size: 1.4rem;
    display: flex;
    flex-flow: column;
    margin-left: auto;
    width: 100%;

    @include mq(m) {
      display: flex;
      flex-flow: row wrap;
      width: auto;
    }

    &__label {
      display: flex;
      align-items: center;
      color: $c-main-primary;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include mq(m) {
        margin-bottom: unset;
      }

      > svg {
        margin-right: 0.6rem;
        fill: $c-main-primary;
      }
    }
  }

  .select-box {
    @include mq(m) {
      margin-left: 0.5rem;
      margin-bottom: unset;
    }
    flex-flow: row wrap;
    margin-bottom: 1rem;
  }
}

::v-deep .modal {
  border-radius: 20px;
  max-width: 80rem;

  .modal-body {
    padding: 1rem 1rem;
    margin: 20px 20px 0;
  }
}
